import React from 'react';
import { Link } from 'react-router-dom';
import { CONFIG } from '../config';
import { useRegisterSection } from '../hooks/useRegisterSection';

interface TeamMember {
	name: string;
	role: string;
	linkedin_url: string;
	profile_picture: string | null;
}

interface TeamProps {
	members: TeamMember[];
}

const Team: React.FC<TeamProps> = ({ members }) => {
	useRegisterSection('team', 'Team');

	return (
		<section className="bg-white py-24 sm:py-32" id="team">
			<div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
				<div className="max-w-2xl">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our team</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">We free your team to focus on skills and strategies that matter.</p>
					<Link to="/team" className="inline-block mt-8 text-lg font-semibold tracking-tight text-gray-900 hover:text-gray-600">
						View full profiles
					</Link>
				</div>
				<ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
					{members.map((member: TeamMember, index: number) => (
						<li key={index} className="flex items-center gap-x-6">
							<img className="h-24 w-24 rounded-full" src={member.profile_picture ? `${CONFIG.STATIC_URL}${member.profile_picture}` : `${CONFIG.STATIC_URL}/static/images/avatar.png`} alt={member.name} />
							<div>
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">{member.name}</h3>
								<p className="text-gray-500">{member.role || 'N/A'}</p>
								<ul className="flex gap-x-2 mt-2">
									<li>
										<a href={member.linkedin_url || '#'} className="text-gray-500 hover:text-gray-900">
											<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24">
												<path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
											</svg>
										</a>
									</li>
								</ul>
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default Team;
