import React from 'react';
import { CONFIG } from '../config';

interface BrandPartnersProps {
	title: string;
	subtitle: string;
	partners: Array<{
		name: string;
		logo: string | null;
		website: string;
	}>;
}

const BrandPartners: React.FC<BrandPartnersProps> = ({ title, subtitle, partners }) => {
	const isSinglePartner = partners.length === 1;

	return (
		<section className="py-16">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl font-bold text-center mb-2">{title}</h2>
				<p className="text-xl text-center text-gray-600 mb-8">{subtitle}</p>
				<div className={`grid ${isSinglePartner ? 'justify-center' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 justify-items-center'}`}>
					{partners.map((partner, index) => (
						<a key={index} href={partner.website} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center transition-transform hover:scale-105">
							{partner.logo ? (
								<img src={`${CONFIG.STATIC_URL}${partner.logo}`} alt={partner.name} className="max-h-20 w-auto object-contain" />
							) : (
								<div className="w-20 h-20 bg-gray-200 flex items-center justify-center rounded-full">
									<span className="text-gray-500 font-bold text-3xl">{partner.name.charAt(0)}</span>
								</div>
							)}
						</a>
					))}
				</div>
			</div>
		</section>
	);
};

export default BrandPartners;
