import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || (process.env.NODE_ENV === 'production' ? 'https://api.talentshake.io/api' : 'http://127.0.0.1:8000/api');

export interface Product {
	name: string;
	display_name: string;
	slug: string;
	description: string;
	status: string;
}

export interface ProductLanding {
	product: {
		name: string;
		display_name: string;
		description: string;
		image: string | null;
		url: string;
		slug: string;
		meta_description: string;
		keywords: string;
		schema_type: string;
		meta_title: string;
		canonical_url: string;
		language: string;
		status: string;
	};
	landing: {
		hero_title: string;
		hero_description: string;
		hero_cta: Array<{
			text: string;
			link: string;
			style: string;
		}>;
		features_title: string;
		features_subtitle: string | null;
		features: Array<{
			title: string;
			description: string;
			icon: string | null;
		}> | null;
		features_cta: Array<{
			text: string;
			link: string;
			style: string;
		}>;
		demo_video_url: string;
		waiting_list_title: string;
		waiting_list_subtitle: string;
		waiting_list_cta: Array<{
			text: string;
			link: string;
			style: string;
		}>;
		meta_description: string;
		meta_keywords: string;
		og_title: string;
		og_description: string;
		og_image: string | null;
		meta_title: string;
		canonical_url: string;
		schema_type: string;
		language: string;
	};
	faq: Array<{ question: string; answer: string }>;
	team: Array<{
		name: string;
		role: string;
		linkedin_url: string;
		profile_picture: string | null;
	}>;
	pricing: {
		title: string;
		subtitle: string;
		cards: PricingCard[];
	} | null;
	jsonld?: any;
	testimonials: {
		title: string;
		subtitle: string;
		items: Array<{
			name: string;
			position: string;
			company: string;
			quote: string;
			photo: string | null;
		}>;
	};
	partners: {
		title: string;
		subtitle: string;
		items: Array<{
			name: string;
			logo: string;
			website: string;
		}>;
	};
	products?: Product[];
}

export interface Author {
	name: string;
	username: string;
	profile_picture: string | null;
	job_title: string | null;
	linkedin_url: string | null;
}

export interface Article {
	title: string;
	content: string;
	reference_article: string | null;
	image: string | null;
	url: string;
	category: string;
	slug: string;
	product__name: string | null;
	meta_description: string;
	keywords: string;
	schema_type: string;
	meta_title: string;
	canonical_url: string;
	language: string;
	update_frequency: string;
	published_date: string;
	last_updated: string;
	og_title?: string;
	og_description?: string;
	og_image?: string;
	schema_markup?: any;
	jsonld?: any;
	authors: Author[];
	is_new: boolean; // Add this line
}

export interface PricingFeature {
	text: string;
	included: boolean;
}

export interface PricingCard {
	title: string;
	price: string;
	description: string;
	features: PricingFeature[];
	button_text: string;
	button_link: string;
	pill_text: string | null;
	second_button_text: string | null;
	second_button_link: string | null;
}

export interface FullTeamMember {
	name: string;
	role: string;
	linkedin_url: string | null;
	profile_picture: string | null;
	description: string | null;
	email: string;
}

export const api = {
	getAllProducts: async (): Promise<Product[]> => {
		const response = await axios.get(`${API_BASE_URL}/products/`);
		return response.data;
	},

	getProductLanding: async (slug: string): Promise<ProductLanding> => {
		const response = await axios.get(`${API_BASE_URL}/product/${slug}/`);
		return response.data;
	},

	joinWaitingList: async (data: { name: string; email: string; motivations: string; product_name: string; interview_participant: boolean }): Promise<{ status: string; position: number; product: string }> => {
		try {
			const response = await axios.post(`${API_BASE_URL}/waiting-list/`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				console.error('Server response:', error.response.data);
				throw error;
			}
			throw error;
		}
	},
	getNavigation: async (): Promise<Product[]> => {
		const response = await axios.get(`${API_BASE_URL}/navigation/`);
		return response.data;
	},

	getFooter: async (): Promise<Article[]> => {
		const response = await axios.get(`${API_BASE_URL}/footer/`);
		return response.data;
	},

	getArticles: async (product?: string): Promise<Article[]> => {
		const url = product ? `${API_BASE_URL}/articles2/?product=${product}` : `${API_BASE_URL}/articles2/`;
		const response = await axios.get(url);

		// Add is_new property based on publication date
		const currentDate = new Date();
		const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);

		return response.data.map((article: Article) => ({
			...article,
			is_new: new Date(article.published_date) > oneWeekAgo,
		}));
	},

	getArticle: async (slug: string): Promise<Article> => {
		const response = await axios.get(`${API_BASE_URL}/articles2/${slug}/`);
		return response.data;
	},

	getFullTeam: async (): Promise<FullTeamMember[]> => {
		const response = await axios.get(`${API_BASE_URL}/full-team/`);
		return response.data;
	},

	runSeoOptimization: async (): Promise<{ message: string }> => {
		const response = await axios.post(`${API_BASE_URL}/run-seo-optimization/`);
		return response.data;
	},
	runArticleGeneration: async (): Promise<{ status: string; message: string }> => {
		const response = await axios.post(`${API_BASE_URL}/run-article-generation/`);
		return response.data;
	},
	runPolicyGeneration: async (): Promise<{ status: string; message: string }> => {
		const response = await axios.post(`${API_BASE_URL}/run-policy-generation/`);
		return response.data;
	},
};
