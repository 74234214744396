import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>404 - Page Not Found | TalentShake</title>
				<meta name="robots" content="noindex, follow" />
			</Helmet>
			<div className="flex items-center justify-center min-h-screen bg-gray-100">
				<div className="text-center">
					<h1 className="text-6xl font-bold text-gray-900">404</h1>
					<p className="text-2xl font-semibold text-gray-600 mt-4">Page Not Found</p>
					<p className="mt-4 text-gray-500">The page you're looking for doesn't exist or has been moved.</p>
					<Link to="/" className="mt-6 inline-block px-6 py-3 bg-pink-500 text-white rounded-md hover:bg-pink-600 transition-colors">
						Go Home
					</Link>
				</div>
			</div>
		</>
	);
};

export default NotFound;
