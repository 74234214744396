import React, { useState } from 'react';
import { useRegisterSection } from '../hooks/useRegisterSection';

interface FAQItem {
	question: string;
	answer: string;
}

interface FAQProps {
	faqs: FAQItem[];
}

const FAQ: React.FC<FAQProps> = ({ faqs }) => {
	useRegisterSection('faq', 'FAQ');
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const toggleFAQ = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<section className="bg-white py-16 sm:py-24" id="faq">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:text-center">
					<h2 className="text-base font-semibold leading-7 text-pink-500">FAQ</h2>
					<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Frequently Asked Questions</p>
				</div>
				<div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
					{faqs.map((faq, index) => (
						<div key={index} className="mb-6 border-b border-gray-200 pb-6 last:border-b-0 last:pb-0">
							<button className="flex w-full justify-between items-center text-left text-lg font-medium text-gray-900 focus:outline-none" onClick={() => toggleFAQ(index)} aria-expanded={openIndex === index}>
								<span>{faq.question}</span>
								<svg className={`h-5 w-5 text-gray-500 transition-transform ${openIndex === index ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
								</svg>
							</button>
							{openIndex === index && <div className="mt-4 text-base text-gray-500 animate-fadeIn">{faq.answer}</div>}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default FAQ;
