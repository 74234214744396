import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
	label: string;
	url: string;
}

interface BreadcrumbProps {
	items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
	return (
		<nav className="text-sm breadcrumbs">
			<ol className="flex items-center space-x-2">
				{items.map((item, index) => (
					<li key={index} className="flex items-center">
						{index > 0 && <span className="mx-2 text-gray-400">/</span>}
						{index === items.length - 1 ? (
							<span className="text-gray-600">{item.label}</span>
						) : (
							<Link to={item.url} className="text-blue-600 hover:underline">
								{item.label}
							</Link>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
