import React, { useState } from 'react';
import { api } from '../services/api';

type OperationType = 'seo' | 'article' | 'policy';

const ContentManagementPage: React.FC = () => {
	const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
	const [message, setMessage] = useState('');
	const [operationType, setOperationType] = useState<OperationType>('seo');

	const handleOperation = async () => {
		setStatus('loading');
		setMessage(`Running ${getOperationName(operationType)}...`);

		try {
			let result;
			switch (operationType) {
				case 'seo':
					result = await api.runSeoOptimization();
					break;
				case 'article':
					result = await api.runArticleGeneration();
					break;
				case 'policy':
					result = await api.runPolicyGeneration();
					break;
			}
			setStatus('success');
			setMessage(result.message);
		} catch (error) {
			setStatus('error');
			setMessage(`An error occurred during ${getOperationName(operationType)}`);
			console.error(`${getOperationName(operationType)} error:`, error);
		}
	};

	const getOperationName = (type: OperationType): string => {
		switch (type) {
			case 'seo':
				return 'SEO Optimization';
			case 'article':
				return 'Article Generation';
			case 'policy':
				return 'Policy Generation';
		}
	};

	return (
		<div className="container mx-auto mt-8 p-4">
			<h1 className="text-2xl font-bold mb-4">Content Management</h1>
			<div className="mb-4">
				<label className="mr-4">
					<input type="radio" value="seo" checked={operationType === 'seo'} onChange={() => setOperationType('seo')} className="mr-2" />
					SEO Optimization
				</label>
				<label className="mr-4">
					<input type="radio" value="article" checked={operationType === 'article'} onChange={() => setOperationType('article')} className="mr-2" />
					Generate Articles
				</label>
				<label>
					<input type="radio" value="policy" checked={operationType === 'policy'} onChange={() => setOperationType('policy')} className="mr-2" />
					Generate Policies
				</label>
			</div>
			<button onClick={handleOperation} disabled={status === 'loading'} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
				{status === 'loading' ? 'Processing...' : `Run ${getOperationName(operationType)}`}
			</button>
			{message && <p className={`mt-4 ${status === 'error' ? 'text-red-600' : 'text-green-600'}`}>{message}</p>}
		</div>
	);
};

export default ContentManagementPage;
