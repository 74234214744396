import * as Progress from '@radix-ui/react-progress';
import * as Slider from '@radix-ui/react-slider';
import { ArrowRight, BarChart, Briefcase, Calendar, DollarSign, FileText, Rocket, Smile, Users, Zap } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

// @ts-ignore
const Recharts = require('recharts');

const contentData = {
	explanationLevels: [
		{ name: 'Seasoned People Ops Investor', value: 0, icon: <Briefcase size={24} /> },
		{ name: "I'm the Investor's 10-year-old", value: 1, icon: <Smile size={24} /> },
		{ name: 'I Need Details', value: 2, icon: <FileText size={24} /> },
		{ name: 'TL;DR', value: 3, icon: <Zap size={24} /> },
	],
	levels: [
		{
			pageTitle: 'Your People Ops AI Partner',
			overviewTitle: "We're Not Just Another HR Tech Startup",
			overview: "HR tech isn't sexy. We get it. That's why we're not HR tech. We're a People Ops AI partner that delivers real, measurable ROI. We create new AI agents in under 7 days, integrate seamlessly with your existing tools, and tie our success directly to your ROI. No fluff, just results.",
			productTitle: 'Our AI Agents: Built for Results',
			product: '1. HelenD: 24/7 post-training support. Employees apply what they learn, immediately.\n2. TiNA: Automated TNA. Data collection, skill gap analysis, actionable insights. Done.\n\nAnd remember, we can create new agents in under a week. Your needs evolve? So do we.',
			marketInfo: [
				{
					title: 'The Problem',
					content: "Companies lose $13.5M yearly per 1,000 employees from poor training application. 40% of manager time is wasted on repetitive questions. TNAs cost €5K-€10K per cycle. That's money down the drain.",
				},
				{
					title: 'Market Opportunity',
					content: "$47 billion global HR tech market. Our focus: SMEs with 200-800 employees. The underserved middle that's ripe for innovation.",
				},
				{
					title: 'Our Edge',
					content: 'Rapid Development: New AI agents in under 7 days. Seamless Integration: No new platforms, we work with what you have. Tangible Results: Our success is tied directly to your ROI.',
				},
				{
					title: 'Business Model',
					content: 'Value-based pricing tied to client ROI. We only win when you win. No more paying for shelf-ware or unused features.',
				},
			],
			tractionTitle: 'Traction That Matters',
			traction: ['1 pilot with an industry leader ready to go', '10 design partners actively testing HelenD', '7 strategic advisors with real-world experience onboard', '€30K in initial funding secured, with potential for more'],
			growthTitle: 'Our Roadmap to Domination',
			roadmapTitle: 'Next 12 Months at a Glance',
			roadmap: ['Q4 2024: Launch HelenD, secure first paying customer, raise additional funding', 'Q1 2025: Scale customer acquisition, expand AI capabilities based on real user feedback', 'Q2 2025: Initiate ISO 27001 certification, forge strategic partnerships for growth', 'Q4 2025: Complete certifications, focus on expansion revenue, refine our AI-driven business model'],
			askTitle: 'What We Need to Win',
			askContent: "We're raising €153K to fuel our growth over the next 12 months. This isn't just about keeping the lights on – it's about dominating the People Ops AI space.",
			askBreakdown: ['€70.6K: Keep our founders 100% focused on scaling TalentShake', '€40K: Supercharge our AI capabilities and spawn new agents faster', '€15K: Targeted marketing to reach the right decision-makers', '€27.4K: Agility fund for rapid development and strategic pivots'],
			finalAsk: "Ready to revolutionize People Ops with AI that actually delivers? We're not just looking for money. We want partners who understand the massive potential in transforming how companies manage and develop their people. Let's reshape the future of work together.",
		},
		{
			pageTitle: 'Super Smart Helpers for Grown-up Work',
			overviewTitle: "What's TalentShake?",
			overview: "TalentShake makes super smart computer friends that help grown-ups do their jobs better! We're like the inventors of friendly robot assistants that make work more fun and less boring.",
			productTitle: 'Our Amazing Robot Helpers',
			product: "1. HelenD: She's like a friendly teacher who's always ready to help people remember what they learned in class.\n2. TiNA: She's super good at figuring out what new things people need to learn to be even better at their jobs.\n\nAnd guess what? We can make new robot helpers really fast, like in less than a week!",
			marketInfo: [
				{
					title: 'The Big Problem',
					content: "Sometimes, grown-ups forget important stuff they learned at work, or they spend too much time answering the same questions over and over. It's like if you had to keep telling your friends the rules of a game every time you played!",
				},
				{
					title: 'How Many People Need Help?',
					content: "Imagine if every person in a big sports stadium needed help doing their job better. That's how many people we can help!",
				},
				{
					title: "Why We're Special",
					content: "Our robot helpers are like super-smart best friends at work. They fit right in with the computer stuff people already use, and they're really good at making work easier and more fun.",
				},
				{
					title: 'How We Get Paid',
					content: "We only get paid when we actually help people do their jobs better. It's like if you only had to share your allowance when your robot toy actually cleaned your room!",
				},
			],
			tractionTitle: "Cool Things We've Done",
			traction: ['One big company is trying out our robot helper right now!', 'Ten companies are playing with HelenD to see how awesome she is', 'Seven really smart grown-ups are helping us make our robots even better', "We got some money (€30,000 - that's like 30,000 ice creams!) to help build our robot helpers"],
			growthTitle: 'Our Big Plans',
			roadmapTitle: 'What We Want to Do Next',
			roadmap: ['This year: Let more people try HelenD and make her even smarter', 'Next year: Help lots more companies and make our robots understand even more stuff', 'The year after: Make sure our robots are super safe and help people all over the world'],
			askTitle: 'What We Need to Make This Happen',
			askContent: "We need €153,000 (that's like 153,000 ice creams!) to make our robot helpers even better and tell more people about them.",
			askBreakdown: ['Money to pay the people who make the robots', 'Money to make the robots even smarter', 'Money to tell everyone about our awesome robots', 'Some extra money in case we need to buy robot spare parts'],
			finalAsk: "Do you know any grown-ups who want to help make work more fun with super-smart robot helpers? We'd love to meet them and show them how cool our robots are!",
		},
		{
			pageTitle: 'Comprehensive Overview of Our People Operations AI Solution',
			overviewTitle: 'Detailed Company Overview and Vision',
			overview: "TalentShake AI is elevating People Operations and Learning & Development for SMEs through ethical AI integration. We provide AI 'colleagues' that seamlessly integrate with existing tools like Slack, Teams, and email, streamlining repetitive tasks without adding platform complexity. Our mission is to boost talent in an AI-driven world, focusing on enhancing human potential rather than replacing it. We prioritize data security, transparency, and a human-centric approach, making us a unique force in the People Ops tech landscape.",
			productTitle: 'In-Depth Product Information',
			product:
				'Our AI suite consists of two primary agents, with the capability to rapidly develop new ones as needed:\n\n1. HelenD: A post-training support AI that provides real-time, context-aware assistance. HelenD integrates with communication tools to answer questions after training sessions, boosting knowledge retention and application. This addresses the $13.5 million annual loss per 1,000 employees due to ineffective training application.\n\n2. TiNA (Training Needs Analysis agent): Currently in development, TiNA will automate the TNA process by collecting data, analyzing skill gaps, and generating actionable insights. This will significantly reduce the time and cost associated with traditional TNA methods, which can cost €5,000 to €10,000 per cycle.\n\nBoth agents use AES-256 encryption and are built on secure cloud infrastructure from AWS and DigitalOcean. We can develop new, customized AI agents within 7 days to address specific client needs.',
			marketInfo: [
				{
					title: 'Comprehensive Market Analysis',
					content: 'The global People Operations tech market is valued at $47 billion, with an expected CAGR of 9.6%. Our focus on SMEs with 200-800 employees addresses a significant, underserved segment often overlooked by larger competitors. Key target industries include call centers, training companies, and manufacturing, which face substantial inefficiencies in People Ops and L&D processes.',
				},
				{
					title: 'Detailed Competitive Landscape',
					content: 'Direct competitors include Degreed, LearnIn, and Eightfold AI. Our differentiation lies in our human-centered design, lean and agile structure, and seamless integration into existing workflows. Unlike larger players with high costs and complex setups, we offer a more adaptable and cost-effective solution tailored for SMEs. Our ability to rapidly develop new AI agents (within 7 days) gives us a significant edge in addressing specific client needs.',
				},
				{
					title: 'In-Depth Value Proposition',
					content: '1. Seamless Integration: Our AI agents work within existing tools, eliminating the need for new platforms.\n2. Rapid Development: We can create new, customized AI agents within 7 days.\n3. Ethical AI: We focus on empowering talent, not replacing it.\n4. Data Security: We use AES-256 encryption and secure cloud infrastructure.\n5. Transparency: We educate customers about AI functionalities and provide human oversight.',
				},
				{
					title: 'Comprehensive Business Model',
					content: 'We employ a value-based pricing model tied directly to the ROI we generate for clients. This ensures companies only pay based on the savings or productivity boosts they experience. For example, if our AI agents save an HR team €1,000 per month, we might charge 20% of that value. This model is scalable and ensures alignment between the value we deliver and the price customers pay. For smaller companies, we implement a minimum fee to cover operational costs.',
				},
			],
			tractionTitle: 'Detailed Traction and Milestones',
			traction: [
				'Secured 1 pilot with an industry-leading company in the call center sector',
				'Engaged 10 design partners actively testing HelenD, providing invaluable feedback for product refinement',
				'Onboarded 7 strategic advisors with deep industry expertise, including Derek Coetzee (30+ years in tech) and Joni Roylance (20 years in HR)',
				'Secured €10K in angel investment and a €20K LEO grant, with potential for an additional €30K',
				'Developing a new website featuring an AI SEO agent to improve online visibility',
				'Preparing to rebrand to Uttū AI to better reflect our mission of empowering talent ethically',
			],
			growthTitle: 'Comprehensive Growth Strategy and Projections',
			roadmapTitle: 'Detailed Strategic Roadmap',
			roadmap: [
				'Q4 2024: Complete testing of HelenD, secure first pilot and paying customer, raise additional angel investment, rebrand to Uttū AI',
				"Q1 2025: Scale customer acquisition efforts leveraging advisors' networks, expand product features based on user feedback, secure additional €20K LEO funding",
				'Q2 2025: Initiate ISO 27001 certification process, secure partnerships through the Enterprise Europe Network (EEN), grow customer base in call centers and training companies',
				'Q4 2025: Complete ISO 27001 certification, focus on increasing expansion revenue, refine business model based on collected feedback and ROI data',
			],
			askTitle: 'Comprehensive Investment Requirements and Allocation',
			askContent: 'We are raising €153K to fund 12 months of operations and growth. This investment will enable us to complete product testing, launch HelenD, develop our Go-To-Market strategy, and ensure operational flexibility as we refine product-market fit.',
			askBreakdown: ["€70.6K: Founders' salaries (€2,941.67/month each) to ensure full-time focus on scaling TalentShake", '€40K: Product development, testing, and external resources to enhance HelenD and develop TiNA', '€15K: Marketing and customer acquisition efforts to increase market presence', '€5K: New development laptop for our CTO to ensure optimal productivity', '€5K: Onboarding an influencer in the People Ops & L&D space for ethical promotion', '€17.4K: Operational buffer for unexpected costs during development and scaling'],
			finalAsk: 'We are seeking strategic partnerships with People Operations or L&D decision-makers in SMEs (200-800 employees) and potential angel investors who align with our vision of ethical AI in People Ops. Our commitment to rapid development, seamless integration, and tangible ROI positions us uniquely in the market. Join us in reshaping the future of work through AI-empowered talent management.',
		},
		{
			pageTitle: 'Quick Summary',
			overviewTitle: 'TL;DR',
			overview: 'TalentShake AI automates People Ops and L&D tasks for SMEs. Our AI agents integrate with existing tools, saving time and resources. We prioritize ethical AI use, ensuring technology empowers rather than replaces talent.',
			productTitle: 'Our AI Agents',
			product: '1. HelenD: Provides post-training support, enhancing knowledge application.\n2. TiNA: Automates Training Needs Analysis (in development).\n\nBoth integrate seamlessly. New agents developed in 7 days as needed.',
			marketInfo: [
				{
					title: 'Market',
					content: '$47B global People Ops tech market, 9.6% CAGR. Target: SMEs (200-800 employees).',
				},
				{
					title: 'Edge',
					content: 'Rapid development (7 days for new agents), seamless integration, ethical AI focus.',
				},
				{
					title: 'Model',
					content: 'Value-based pricing tied to client ROI. We win when clients win.',
				},
			],
			tractionTitle: 'Traction Highlights',
			traction: ['1 pilot with industry leader', '10 design partners testing HelenD', '7 strategic advisors onboard', '€30K funding secured'],
			growthTitle: 'Growth Plans',
			roadmapTitle: 'Key Milestones',
			roadmap: ['2024: Launch HelenD, secure first customers', '2025: Scale customers, expand features, achieve ISO 27001 certification'],
			askTitle: 'Investment Needs',
			askContent: 'Raising €153K for 12 months of growth and development.',
			askBreakdown: ["€70.6K: Founders' salaries", '€40K: Product development', '€15K: Marketing', '€27.4K: Operations and contingency'],
			finalAsk: 'Seeking strategic partners and investors aligned with our vision of ethical AI in People Ops. Join us in reshaping the future of work.',
		},
	],
};

const projectedGrowth = [
	{ year: '2024', users: 100 },
	{ year: '2025', users: 500 },
	{ year: '2026', users: 2000 },
	{ year: '2027', users: 5000 },
	{ year: '2028', users: 10000 },
];

const InvestorPage = () => {
	const [explanationLevel, setExplanationLevel] = useState(2);
	const [fundingProgress, setFundingProgress] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => setFundingProgress(10), 500);
		return () => clearTimeout(timer);
	}, []);

	const { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } = Recharts;

	const handleLevelClick = (value: number) => {
		setExplanationLevel(value);
	};

	const levelContent = contentData.levels[explanationLevel];

	const renderMarkdown = (content: string) => {
		return <ReactMarkdown>{content}</ReactMarkdown>;
	};

	return (
		<div className="max-w-4xl mx-auto p-4 sm:p-8 font-sans">
			<h1 className="text-5xl font-bold mb-4 text-center text-gray-800">Investor Information</h1>
			<h2 className="text-3xl font-semibold mb-8 text-center text-gray-700">{levelContent.pageTitle}</h2>

			<div className="mb-12 bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">Choose Your Adventure</h2>
				<div className="relative mb-6">
					<Slider.Root value={[explanationLevel]} max={3} step={1} onValueChange={(value) => setExplanationLevel(value[0])} className="relative flex items-center select-none touch-none w-full h-10">
						<Slider.Track className="bg-gray-300 relative grow rounded-full h-2">
							<Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
						</Slider.Track>
						<Slider.Thumb className="block w-8 h-8 bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-md" />
					</Slider.Root>
					<div className="absolute top-0 left-0 right-0 flex justify-between pointer-events-none">
						{contentData.explanationLevels.map((level) => (
							<div key={level.value} className="w-8 h-8" />
						))}
					</div>
				</div>
				<div className="flex flex-col sm:flex-row justify-between text-sm text-gray-600">
					{contentData.explanationLevels.map((level) => (
						<div key={level.value} className={`flex items-center mb-2 sm:mb-0 cursor-pointer ${explanationLevel === level.value ? 'font-bold text-blue-500' : ''}`} onClick={() => handleLevelClick(level.value)}>
							<div className="mr-2">{level.icon}</div>
							<span>{level.name}</span>
						</div>
					))}
				</div>
			</div>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.overviewTitle}</h2>
				<div className="text-gray-600 mb-4">{renderMarkdown(levelContent.overview)}</div>
			</section>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.productTitle}</h2>
				<div className="text-gray-600 mb-4">{renderMarkdown(levelContent.product)}</div>
			</section>

			<section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
				{levelContent.marketInfo.map((info, index) => (
					<div key={index} className="bg-white p-6 rounded-lg shadow-md">
						<h3 className="text-xl font-semibold mb-3 flex items-center text-gray-700">
							{index === 0 && <BarChart className="mr-2 text-blue-500" />}
							{index === 1 && <Users className="mr-2 text-green-500" />}
							{index === 2 && <Rocket className="mr-2 text-yellow-500" />}
							{index === 3 && <DollarSign className="mr-2 text-purple-500" />}
							{info.title}
						</h3>
						<div className="text-gray-600">{renderMarkdown(info.content)}</div>
					</div>
				))}
			</section>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.tractionTitle}</h2>
				<ul className="list-disc pl-6 text-gray-600">
					{levelContent.traction.map((item, index) => (
						<li key={index}>{renderMarkdown(item)}</li>
					))}
				</ul>
			</section>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.growthTitle}</h2>
				<div className="h-64 w-full">
					<ResponsiveContainer width="100%" height="100%">
						<LineChart data={projectedGrowth}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="year" />
							<YAxis />
							<Tooltip />
							<Line type="monotone" dataKey="users" stroke="#8884d8" activeDot={{ r: 8 }} />
						</LineChart>
					</ResponsiveContainer>
				</div>
			</section>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.roadmapTitle}</h2>
				{levelContent.roadmap.map((step, index) => (
					<div key={index} className="flex items-start mb-3">
						<Calendar className={`mr-3 mt-1 text-${['blue', 'green', 'yellow', 'purple'][index]}-500 flex-shrink-0`} />
						<div className="text-gray-600">{renderMarkdown(step)}</div>
					</div>
				))}
			</section>

			<section className="mb-12">
				<h2 className="text-2xl font-semibold mb-4 text-gray-700">{levelContent.askTitle}</h2>
				<div className="text-gray-600 mb-4">{renderMarkdown(levelContent.askContent)}</div>
				<ul className="list-disc pl-6 text-gray-600 mb-4">
					{levelContent.askBreakdown.map((item, index) => (
						<li key={index}>{renderMarkdown(item)}</li>
					))}
				</ul>
				<div className="mt-6">
					<h3 className="text-xl font-semibold mb-2 text-gray-700">Funding Progress</h3>
					<Progress.Root value={fundingProgress} className="relative overflow-hidden bg-gray-300 rounded-full w-full h-4" max={100}>
						<Progress.Indicator className="w-full h-full bg-blue-500 transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]" style={{ transform: `translateX(-${100 - fundingProgress}%)` }} />
					</Progress.Root>
					<p className="text-right mt-2 text-gray-600">{fundingProgress}% of €153K goal</p>
				</div>
				<div className="text-gray-600 mt-4">{renderMarkdown(levelContent.finalAsk)}</div>
			</section>

			<div className="text-center">
				<button className="bg-blue-500 text-white px-6 py-3 rounded-full inline-flex items-center hidden">
					<a href="https://talentshake.typeform.com/to/X6555555" className="flex items-center">
						<span className="mr-2">Invest</span>
						<ArrowRight size={16} />
					</a>
				</button>
			</div>
		</div>
	);
};

export default InvestorPage;
