import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api, Article, Product } from '../services/api';
import NewPill from './NewPill';

const ArticlesPage: React.FC = () => {
	const [articlesByProduct, setArticlesByProduct] = useState<Record<string, Article[]>>({});
	const [products, setProducts] = useState<Product[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const fetchedProducts = await api.getAllProducts();
			const articles = await api.getArticles();

			const grouped = articles.reduce((acc, article) => {
				const productName = article.product__name || 'General';
				if (!acc[productName]) acc[productName] = [];
				acc[productName].push(article);
				return acc;
			}, {} as Record<string, Article[]>);

			// Filter products to only include those with articles
			const productsWithArticles = fetchedProducts.filter((product) => grouped[product.name]?.length > 0);

			setProducts(productsWithArticles);
			setArticlesByProduct(grouped);
		};

		fetchData();
	}, []);

	return (
		<div className="container mx-auto px-4 py-8 sm:py-12">
			<h1 className="text-2xl sm:text-3xl font-bold mb-8">Articles by Product</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
				{products.map((product) => (
					<div key={product.slug} className="bg-white rounded-lg shadow-md p-6">
						<h2 className="text-xl font-semibold mb-4">{product.display_name}</h2>
						<ul className="space-y-2">
							{articlesByProduct[product.name]?.map((article) => (
								<li key={article.slug} className="flex items-center justify-between">
									<Link to={`/articles/${article.slug}`} className="text-blue-600 hover:underline">
										{article.title}
									</Link>
									{article.is_new && <NewPill />}
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
};

export default ArticlesPage;
