import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Product } from '../services/api';

interface ProductListProps {
	products: Product[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
	// Filter out the "General" product
	const filteredProducts = products.filter((product) => product.slug.toLowerCase() !== 'general');

	const statusColors = useMemo(() => {
		const statuses = new Set(filteredProducts.map((p) => p.status));
		const colors = ['#3B82F6', '#10B981', '#FBBF24', '#EF4444', '#8B5CF6', '#EC4899', '#6366F1'];
		return Array.from(statuses).reduce((acc, status, index) => {
			acc[status] = colors[index % colors.length];
			return acc;
		}, {} as Record<string, string>);
	}, [filteredProducts]);

	const getStatusColor = (status: string) => statusColors[status] || '#6B7280';

	return (
		<div className="bg-white py-24 sm:py-32">
			<div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
				<div className="max-w-2xl">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our Agents</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">Discover our innovative solutions designed to revolutionize your workflow.</p>
				</div>
				<ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
					{filteredProducts.map((product) => (
						<li key={product.slug}>
							<div className="flex items-start gap-x-6">
								<div className="flex-shrink-0 mt-1">
									<svg className="h-16 w-16 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
										<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
									</svg>
								</div>
								<div>
									<div className="flex items-center gap-x-2">
										<h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
											<Link to={`/${product.slug}`}>{product.display_name}</Link>
										</h3>
										<span
											className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium"
											style={{
												backgroundColor: `${getStatusColor(product.status)}20`,
												color: getStatusColor(product.status),
											}}
										>
											{product.status}
										</span>
									</div>
									<div className="text-sm leading-6 text-gray-600" dangerouslySetInnerHTML={{ __html: product.description }} />
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ProductList;
