// src/App.tsx
import React, { useContext, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ArticleDetail from './components/ArticleDetail';
import ArticlesPage from './components/ArticlesPage';
import ContentManagementPage from './components/ContentManagementPage';
import CookieConsentBanner from './components/CookieConsentBanner';
import ErrorBoundary from './components/ErrorBoundary';
import InvestorPage from './components/InvestorPage';
import JobBoards from './components/JobBoards';
import Layout from './components/Layout';
import NotFound from './components/NotFound';

import ProductLandingPage from './components/ProductLanding';
// import SitemapXml from './components/SitemapXml';
import TeamFullPage from './components/TeamFullPage';
import { ConsentContext, ConsentProvider } from './contexts/ConsentContext';
import { SectionsProvider } from './contexts/SectionsContext';
import { api, Product } from './services/api';

const App: React.FC = () => {
	const [navigationItems, setNavigationItems] = useState<Product[]>([]);
	const [error, setError] = useState<string | null>(null);
	const { cookiesAccepted, setCookiesAccepted } = useContext(ConsentContext);
	const [currentProductName, setCurrentProductName] = useState<string>('general');

	useEffect(() => {
		api
			.getNavigation()
			.then(setNavigationItems)
			.catch((err) => {
				console.error('Error fetching navigation:', err);
				setError('Failed to load navigation data');
			});
	}, []);

	const handleAcceptCookies = () => {
		setCookiesAccepted(true);
	};

	const handleDeclineCookies = () => {
		setCookiesAccepted(false);
	};

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<HelmetProvider>
			<SectionsProvider>
				<Router>
					<Routes>
						<Route
							element={
								<>
									<ErrorBoundary>
										<Layout navigationItems={navigationItems} productName={currentProductName}>
											<Outlet />
										</Layout>
									</ErrorBoundary>
									{!cookiesAccepted && <CookieConsentBanner onAccept={handleAcceptCookies} onDecline={handleDeclineCookies} />}
								</>
							}
						>
							<Route path="/" element={<ProductLandingPage setCurrentProductName={setCurrentProductName} />} />
							<Route path="/content-management" element={<ContentManagementPage />} />
							<Route path="/articles/:slug" element={<ArticleDetail />} />
							<Route path="/team" element={<TeamFullPage />} />
							<Route path="/articles" element={<ArticlesPage />} />
							<Route path="/investor" element={<InvestorPage />} />
							<Route path="/job-boards" element={<JobBoards setCurrentProductName={setCurrentProductName} />} />

							<Route path="/:slug" element={<ProductLandingPage setCurrentProductName={setCurrentProductName} />} />
							<Route path="*" element={<NotFound />} />
						</Route>
					</Routes>
				</Router>
			</SectionsProvider>
		</HelmetProvider>
	);
};

const AppWithProviders = () => (
	<ConsentProvider>
		<App />
	</ConsentProvider>
);

export default AppWithProviders;
