import React, { Component, ErrorInfo, ReactNode } from 'react';
import NotFound from './NotFound';

interface Props {
	children: ReactNode;
}

interface State {
	hasError: boolean;
	is404: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		is404: false,
	};

	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true, is404: error.message === 'Not Found' };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	public render() {
		if (this.state.hasError) {
			return this.state.is404 ? <NotFound /> : <h1>Sorry.. there was an error</h1>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
