import React, { useCallback, useEffect, useState } from 'react';
import { CONFIG } from '../config';

interface TestimonialProps {
	title: string;
	subtitle: string;
	testimonials: Array<{
		name: string;
		position: string;
		company: string;
		quote: string;
		photo: string | null;
	}>;
}

const Testimonials: React.FC<TestimonialProps> = ({ title, subtitle, testimonials }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isPlaying, setIsPlaying] = useState(true);

	const advanceTestimonial = useCallback(() => {
		if (isPlaying) {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
		}
	}, [isPlaying, testimonials.length]);

	useEffect(() => {
		if (isPlaying) {
			const words = testimonials[currentIndex].quote.split(' ').length;
			const readingTime = words * 200; // Assuming 200ms per word
			const timer = setTimeout(advanceTestimonial, readingTime);
			return () => clearTimeout(timer);
		}
	}, [currentIndex, testimonials, advanceTestimonial, isPlaying]);

	const handleNavigation = (direction: 'prev' | 'next') => {
		setCurrentIndex((prevIndex) => {
			const newIndex = direction === 'next' ? (prevIndex + 1) % testimonials.length : (prevIndex - 1 + testimonials.length) % testimonials.length;
			return newIndex;
		});
	};

	const togglePlayPause = () => setIsPlaying(!isPlaying);

	const getPrevIndex = (index: number) => (index - 1 + testimonials.length) % testimonials.length;
	const getNextIndex = (index: number) => (index + 1) % testimonials.length;

	const renderTestimonial = (index: number, className: string) => (
		<div className={`${className} bg-white p-6 rounded-lg shadow-md h-full`}>
			<p className="text-gray-600 italic mb-4">"{testimonials[index].quote}"</p>
			<div className="flex items-center mt-auto">
				{testimonials[index].photo ? (
					<img src={`${CONFIG.STATIC_URL}${testimonials[index].photo}`} alt={testimonials[index].name} className="w-12 h-12 rounded-full mr-4 object-cover" />
				) : (
					<div className="w-12 h-12 bg-gray-200 rounded-full mr-4 flex items-center justify-center">
						<span className="text-gray-500 font-bold text-xl">{testimonials[index].name.charAt(0)}</span>
					</div>
				)}
				<div>
					<p className="font-semibold">{testimonials[index].name}</p>
					<p className="text-sm text-gray-500">
						{testimonials[index].position}, {testimonials[index].company}
					</p>
				</div>
			</div>
		</div>
	);

	return (
		<section className="bg-gray-100 py-16">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl font-bold text-center mb-2">{title}</h2>
				<p className="text-xl text-center text-gray-600 mb-8">{subtitle}</p>
				<div className="relative max-w-4xl mx-auto">
					<div className="flex items-center justify-center">
						<button onClick={() => handleNavigation('prev')} className="text-4xl text-gray-400 hover:text-gray-600 absolute left-4 top-1/2 transform -translate-y-1/2 z-10">
							&#8249;
						</button>
						<div className="flex items-stretch justify-center w-full overflow-hidden">
							<div className="w-3/4 transform scale-90 blur-sm opacity-50 -mr-1/4 shrink-0">{renderTestimonial(getPrevIndex(currentIndex), '')}</div>
							<div className="w-3/4 mx-4 shrink-0">{renderTestimonial(currentIndex, '')}</div>
							<div className="w-3/4 transform scale-90 blur-sm opacity-50 -ml-1/4 shrink-0">{renderTestimonial(getNextIndex(currentIndex), '')}</div>
						</div>
						<button onClick={() => handleNavigation('next')} className="text-4xl text-gray-400 hover:text-gray-600 absolute right-4 top-1/2 transform -translate-y-1/2 z-10">
							&#8250;
						</button>
					</div>
					<button onClick={togglePlayPause} className="mt-6 mx-auto block text-2xl text-gray-600 hover:text-gray-800">
						{isPlaying ? '\u23F8' : '\u25B6'}
					</button>
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
