import { useEffect } from 'react';
// src/contexts/SectionsContext.tsx
import { useSections } from '.././contexts/SectionsContext';

export const useRegisterSection = (id: string, label: string) => {
	const { registerSection, unregisterSection } = useSections();

	useEffect(() => {
		registerSection({ id, label });
		return () => unregisterSection(id);
	}, [id, label, registerSection, unregisterSection]);
};
