import React from 'react';
import CookieConsent from 'react-cookie-consent';

interface CookieConsentProps {
	onAccept: () => void;
	onDecline: () => void;
}

const CookieConsentBanner: React.FC<CookieConsentProps> = ({ onAccept, onDecline }) => {
	return (
		<CookieConsent location="bottom" buttonText="Accept" declineButtonText="Decline" cookieName="talentshake-cookie-consent" onAccept={onAccept} onDecline={onDecline} enableDeclineButton>
			This website uses cookies to enhance the user experience and analyze site traffic.
		</CookieConsent>
	);
};

export default CookieConsentBanner;
