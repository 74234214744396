import React from 'react';
import { useLocation } from 'react-router-dom';
import { Product } from '../services/api';
import Footer from './Footer';
import Navigation from './Navigation';
import ProductList from './ProductList';
import ScrollToTop from './ScrollToTop';
interface LayoutProps {
	navigationItems: Product[];
	children: React.ReactNode;
	productName: string;
}

const Layout: React.FC<LayoutProps> = ({ navigationItems, children, productName }) => {
	return (
		<div className="flex flex-col min-h-screen">
			<Navigation items={navigationItems} />
			<main className="flex-grow pt-16 sm:pt-24">{children}</main>
			<Footer productName={productName} />
			<ScrollToTop />
		</div>
	);
};

export default Layout;
