import { Button, Card, CardContent } from '@mui/material';
import { ArrowRight, BriefcaseIcon, Building, Code, Mail, MoreHorizontal, Rocket, Shield, Users, Zap } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRegisterSection } from '../hooks/useRegisterSection';
import CustomSwitch from './CustomSwitch';
import WaitingList from './WaitingList';

type ViewMode = 'recruiter' | 'talent';

const JobBoards: React.FC<{ setCurrentProductName: React.Dispatch<React.SetStateAction<string>> }> = ({ setCurrentProductName }) => {
	const [viewMode, setViewMode] = useState<ViewMode>('recruiter');
	const [isJiggling, setIsJiggling] = useState(true);

	useEffect(() => {
		// Remove the jiggle class after the animation completes
		const timer = setTimeout(() => setIsJiggling(false), 500);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		setCurrentProductName('job-boards');
	}, [setCurrentProductName]);

	useRegisterSection('features', 'Why?');
	useRegisterSection('job-boards', 'Job Boards');
	useRegisterSection('how-it-works', 'How It Works');
	useRegisterSection('waiting-list', 'Join Waitlist');

	const toggleView = () => {
		setViewMode(viewMode === 'recruiter' ? 'talent' : 'recruiter');
	};

	const content: Record<
		ViewMode,
		{
			hero: { title: string; subtitle: string };
			features: string[];
			cta: string;
		}
	> = {
		recruiter: {
			hero: {
				title: 'Find Top Tech Talents',
				subtitle: 'AI & Cybersecurity Job Boards Opening Soon',
			},
			features: ['Access to specialized talent pools in cutting-edge tech fields', 'Pay per post: $200 or $2000/year unlimited', 'Set custom challenges to assess skills & motivation (Bounties, Article Requests, etc)', 'Discover active talents through their publications, open-source contributions, and community engagement'],
			cta: 'Join the Waitlist',
		},
		talent: {
			hero: {
				title: 'Connect with Top Tech Companies',
				subtitle: 'AI & Cybersecurity Job Boards Opening Soon',
			},
			features: ['Access targeted opportunities in high-demand tech sectors', 'No CV needed - Your profile is your passport', 'Showcase skills through challenges, articles, and open-source contributions', 'Stand out with your expertise and community engagement'],
			cta: 'Get Notified',
		},
	};

	return (
		<>
			<Helmet>
				<title>TalentShake Job Boards | AI & Cybersecurity Jobs</title>
				<meta name="description" content="Specialized job boards for AI and Cybersecurity professionals. Connect top tech talents with innovative companies." />
			</Helmet>
			<div className="max-w-6xl mx-auto px-4 py-8">
				<header className="flex justify-between items-center mb-12 py-4">
					<div className={`flex items-center space-x-4 ${isJiggling ? 'jiggle-animation' : ''}`}>
						<span className={`text-lg ${viewMode === 'recruiter' ? 'font-bold' : ''}`}>Recruiter</span>
						<CustomSwitch checked={viewMode === 'talent'} onChange={toggleView} />
						<span className={`text-lg ${viewMode === 'talent' ? 'font-bold' : ''}`}>Talent</span>
					</div>
				</header>

				<section className="text-center mb-16 text-black border border-gray-200 py-16 rounded-lg" id="hero">
					<h2 className="text-4xl sm:text-5xl font-bold mb-4">{content[viewMode].hero.title}</h2>
					<p className="text-xl mb-8">{content[viewMode].hero.subtitle}</p>
					<Button
						variant="contained"
						startIcon={<Mail />}
						size="large"
						className="bg-white text-blue-600 hover:bg-blue-300 rounded-full"
						onClick={() => {
							const waitingListSection = document.getElementById('waiting-list');
							if (waitingListSection) {
								waitingListSection.scrollIntoView({ behavior: 'smooth' });
							}
						}}
					>
						{content[viewMode].cta}
					</Button>
				</section>

				<section className="mb-16" id="features">
					<h2 className="text-3xl font-semibold mb-8 text-center">Why Our Job Board?</h2>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
						{content[viewMode].features.map((feature, index) => (
							<Card key={index} className="transition-all duration-300 hover:shadow-lg">
								<CardContent className="flex items-start p-6">
									<Zap className="mr-4 mt-1 flex-shrink-0 text-blue-600" size={24} />
									<span className="text-base">{feature}</span>
								</CardContent>
							</Card>
						))}
					</div>
				</section>

				<section className="mb-16" id="job-boards">
					<h2 className="text-3xl font-semibold mb-8 text-center">Job Boards Opening Soon</h2>
					<div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
						{[
							{ icon: Code, title: 'AI', description: 'Connecting top AI talent with innovative companies' },
							{ icon: Shield, title: 'Cybersecurity', description: 'Bridging skilled security professionals with organizations' },
							{ icon: MoreHorizontal, title: 'Many More', description: 'Stay tuned for additional specialized tech job boards' },
						].map(({ icon: Icon, title, description }) => (
							<Card key={title} className="transition-all duration-300 hover:shadow-lg hover:scale-105">
								<CardContent className="flex flex-col items-center text-center p-6">
									<Icon className="mb-4 text-blue-600" size={48} />
									<h3 className="text-xl font-semibold mb-2">{title}</h3>
									<p className="text-base">{description}</p>
								</CardContent>
							</Card>
						))}
					</div>
				</section>

				<section className="mb-16" id="how-it-works">
					<h2 className="text-3xl font-semibold mb-12 text-center">How It Works</h2>
					<div className="flex flex-col md:flex-row items-center justify-center max-w-5xl mx-auto">
						{[
							{ icon: Rocket, title: 'Connect', description: viewMode === 'recruiter' ? 'Post jobs with custom challenges' : 'Create your dynamic profile' },
							{ icon: Users, title: 'Engage', description: viewMode === 'recruiter' ? 'Discover active talents' : 'Showcase your expertise' },
							{ icon: Building, title: 'Succeed', description: viewMode === 'recruiter' ? 'Hire the best fit' : 'Land your ideal role' },
						].map(({ icon: Icon, title, description }, index, array) => (
							<React.Fragment key={title}>
								<div className="flex flex-col items-center text-center mb-8 md:mb-0">
									<Icon size={64} className="mb-4 text-blue-600" />
									<h3 className="text-xl font-semibold mb-2">{title}</h3>
									<p className="text-base max-w-[200px]">{description}</p>
								</div>
								{index < array.length - 1 && <ArrowRight size={32} className="hidden md:block text-gray-400 mx-4 animate-pulse" />}
							</React.Fragment>
						))}
					</div>
				</section>

				<section className="rounded-lg" id="waiting-list">
					<WaitingList productName="job_boards" title={viewMode === 'recruiter' ? 'Ready to Find Your Next Tech Star?' : 'Ready to Boost Your Tech Career?'} subtitle={viewMode === 'recruiter' ? 'Join our exclusive waiting list for early access.' : 'Be the first to know when our job board launches.'} cta={[{ text: content[viewMode].cta, link: '#waiting_list', style: 'primary' }]} onSubmit={() => {}} viewMode={viewMode} />
				</section>
			</div>
		</>
	);
};

export default JobBoards;
