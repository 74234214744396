import React, { useEffect, useState } from 'react';

const ScrollToTop: React.FC = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 300) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', toggleVisibility);

		return () => window.removeEventListener('scroll', toggleVisibility);
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<>
			{isVisible && (
				<div onClick={scrollToTop} className="fixed bottom-5 right-5 cursor-pointer bg-gray-800 hover:bg-gray-700 text-white p-2 rounded-full shadow-lg transition-opacity duration-300" style={{ opacity: isVisible ? '1' : '0' }}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
					</svg>
				</div>
			)}
		</>
	);
};

export default ScrollToTop;
