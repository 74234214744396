import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type Section = {
	id: string;
	label: string;
};

type SectionsContextType = {
	sections: Section[];
	registerSection: (section: Section) => void;
	unregisterSection: (id: string) => void;
};

const defaultSections: Section[] = [{ id: 'products', label: 'Products' }];

const SectionsContext = createContext<SectionsContextType | undefined>(undefined);

export const SectionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sections, setSections] = useState<Section[]>(defaultSections);

	const registerSection = useCallback((section: Section) => {
		setSections((prev) => {
			if (prev.some((s) => s.id === section.id)) return prev;
			return [...prev, section];
		});
	}, []);

	const unregisterSection = useCallback((id: string) => {
		setSections((prev) => prev.filter((s) => s.id !== id || s.id === 'products'));
	}, []);

	const value = useMemo(() => ({ sections, registerSection, unregisterSection }), [sections, registerSection, unregisterSection]);

	return <SectionsContext.Provider value={value}>{children}</SectionsContext.Provider>;
};

export const useSections = () => {
	const context = useContext(SectionsContext);
	if (context === undefined) {
		throw new Error('useSections must be used within a SectionsProvider');
	}
	return context;
};
