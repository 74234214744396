import DOMPurify from 'dompurify';
import React from 'react';
import { useRegisterSection } from '../hooks/useRegisterSection';

interface CTAButton {
	text: string;
	link: string;
	style: string;
}

interface Feature {
	title: string;
	description: string;
	icon: string | null;
}

interface FeaturesProps {
	title: string;
	subtitle: string;
	description: string;
	features: Feature[];
	cta: CTAButton | CTAButton[];
}

const Features: React.FC<FeaturesProps> = ({ title, subtitle, description, features, cta }) => {
	useRegisterSection('features', 'Features');

	const extractContent = (html: string) => {
		const div = document.createElement('div');
		div.innerHTML = DOMPurify.sanitize(html);
		return div.textContent || div.innerText || '';
	};

	const gridCols = features.length === 3 ? 'lg:grid-cols-3' : 'lg:grid-cols-2';
	const ctaButtons = Array.isArray(cta) ? cta : [cta];

	return (
		<section className="bg-white py-16 sm:py-24" id="features">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:text-center">
					<h2 className="text-base font-semibold leading-7 text-pink-500">{subtitle}</h2>
					<h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>
				</div>
				<div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
					<dl className={`grid grid-cols-1 sm:grid-cols-2 ${gridCols} gap-x-6 gap-y-10`}>
						{features.map((feature) => (
							<div key={feature.title} className="relative pl-16">
								<dt className="text-base font-semibold leading-7 text-gray-900">
									<div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#df21aa]">
										{feature.icon ? (
											<img src={feature.icon} alt={feature.title} className="h-6 w-6 text-white" aria-hidden="true" />
										) : (
											<svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
												<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
											</svg>
										)}
									</div>
									{feature.title}
								</dt>
								<dd className="mt-2 text-base leading-7 text-gray-600">{extractContent(feature.description)}</dd>
							</div>
						))}
					</dl>
				</div>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					{ctaButtons.map((button, index) => (
						<a key={index} href={button.link} className={`rounded-full px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${button.style === 'primary' ? 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-indigo-600' : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}>
							{button.text}
						</a>
					))}
				</div>
			</div>
		</section>
	);
};

export default Features;
