// src/contexts/ConsentContext.tsx
import React, { createContext, useState } from 'react';

interface ConsentContextProps {
	cookiesAccepted: boolean;
	setCookiesAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConsentContext = createContext<ConsentContextProps>({
	cookiesAccepted: false,
	setCookiesAccepted: () => {},
});

export const ConsentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [cookiesAccepted, setCookiesAccepted] = useState(false);

	return <ConsentContext.Provider value={{ cookiesAccepted, setCookiesAccepted }}>{children}</ConsentContext.Provider>;
};
