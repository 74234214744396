import React from 'react';
import { useRegisterSection } from '../hooks/useRegisterSection';

interface VideoEmbedProps {
	url: string;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ url }) => {
	useRegisterSection('demo', 'Demo');

	return (
		<section className="bg-white py-16 sm:py-24" id="demo">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl lg:text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Product Demo</h2>
				</div>
				<div className="mt-10 flex justify-center">
					<iframe width="560" height="315" src={url} title="Product Demo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</div>
			</div>
		</section>
	);
};

export default VideoEmbed;
