import React from 'react';

interface CustomSwitchProps {
	checked: boolean;
	onChange: () => void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange }) => {
	return (
		<label className="flex items-center cursor-pointer">
			<div className="relative">
				<input type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
				<div className={`block bg-gray-600 w-14 h-8 rounded-full ${checked ? 'bg-blue-600' : ''}`}></div>
				<div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${checked ? 'transform translate-x-6' : ''}`}></div>
			</div>
		</label>
	);
};

export default CustomSwitch;
