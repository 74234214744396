import React from 'react';
import { Link } from 'react-router-dom';

interface CTAButton {
	text: string;
	link: string;
	style: string;
}

interface HeroProps {
	title: string;
	description: string;
	productSlug: string;
	cta: CTAButton | CTAButton[];
}

const Hero: React.FC<HeroProps> = ({ title, description, productSlug, cta }) => {
	const ctaButtons = Array.isArray(cta) ? cta : [cta];

	return (
		<section className="bg-base-200 overflow-hidden w-full" id="hero">
			<div className="relative isolate px-6 pt-14 lg:px-8">
				<div className="mx-auto max-w-2xl py-24 sm:py-32 lg:py-40">
					<div className="text-center">
						<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h1>
						<div className="mt-6 text-lg leading-8 text-gray-600" dangerouslySetInnerHTML={{ __html: description }} />
						<div className="mt-10 flex items-center justify-center gap-x-6">
							{ctaButtons.map((button, index) => (
								<a key={index} href={button.link} className={`rounded-full px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${button.style === 'primary' ? 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600' : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}>
									{button.text}
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
