import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CONFIG } from '../config';
import { useSections } from '../contexts/SectionsContext';
import { Product } from '../services/api';

interface NavigationProps {
	items: Product[];
	jobBoardsLink?: string;
}

const Navigation: React.FC<NavigationProps> = ({ items, jobBoardsLink = '/job-boards' }) => {
	const [isDesktopDropdownOpen, setIsDesktopDropdownOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const { sections } = useSections();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsDesktopDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleNavigation = (path: string) => {
		setIsMobileMenuOpen(false);
		setIsDesktopDropdownOpen(false);
		setIsMobileDropdownOpen(false);
		navigate(path);
		// Scroll to the top of the page
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleSectionClick = (sectionId: string) => {
		setIsMobileMenuOpen(false);
		const element = document.getElementById(sectionId);
		element?.scrollIntoView({ behavior: 'smooth' });
	};

	const allItems = [...items, { slug: 'job-boards', display_name: 'Job Boards' }];
	const navigationSections = sections.filter((section) => section.label.toLowerCase() !== 'products');

	return (
		<>
			<header className="absolute inset-x-0 top-0 z-40">
				<nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
					<div className="flex lg:flex-1 items-center">
						<Link to="/" className="-m-1.5 p-1.5">
							<img className="h-16 w-auto sm:h-20 md:h-24" src={`${CONFIG.MEDIA_URL}/static/images/logo-white-blk.png`} alt="TalentShake" />
						</Link>
					</div>
					<div className="hidden lg:flex lg:gap-x-12">
						<div className="relative" ref={dropdownRef}>
							<button onClick={() => setIsDesktopDropdownOpen(!isDesktopDropdownOpen)} className="text-sm font-semibold leading-6 text-gray-900" id="products-dropdown">
								Products
							</button>
							{isDesktopDropdownOpen && (
								<div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" id="dropdown-menu">
									{allItems.map((product) => (
										<button key={product.slug} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleNavigation(product.slug === 'job-boards' ? jobBoardsLink : product.slug === 'general' ? '/' : `/${product.slug}`)}>
											{product.display_name}
										</button>
									))}
								</div>
							)}
						</div>
						{sections
							.filter((section) => section.label.toLowerCase() !== 'products') // Filter out the "Products" section
							.map((section) => (
								<button key={section.id} className="text-sm font-semibold leading-6 text-gray-900 hover:text-pink-500" onClick={() => handleSectionClick(section.id)}>
									{section.label}
								</button>
							))}
					</div>
					<div className="hidden lg:flex lg:flex-1 lg:justify-end">
						<a href="https://hr.admin.talentshake.io/login/" className="hidden text-sm font-semibold leading-6 text-gray-900">
							Log in <span aria-hidden="true">→</span>
						</a>
					</div>
				</nav>
			</header>

			{/* Floating Navigation Dock */}
			{!isMobileMenuOpen && (
				<div className="fixed bottom-4 left-0 right-0 flex justify-center z-50 lg:hidden">
					<motion.button whileTap={{ scale: 0.95 }} onClick={() => setIsMobileMenuOpen(true)} className="bg-white text-gray-900 p-3 rounded-full shadow-lg">
						<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					</motion.button>
				</div>
			)}

			{/* Mobile Menu Overlay */}
			<AnimatePresence>
				{isMobileMenuOpen && (
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="fixed inset-0 z-50 lg:hidden">
						<div className="absolute inset-0 bg-gray-600/40 backdrop-blur-sm" />
						<motion.div ref={menuRef} initial={{ scale: 0.95, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.95, opacity: 0 }} className="fixed inset-6 overflow-y-auto rounded-2xl bg-white shadow-xl flex flex-col">
							{/* Logo */}
							<div className="flex justify-center mt-8 mb-6">
								<img className="h-16 w-auto" src={`${CONFIG.MEDIA_URL}/static/images/logo-white-blk.png`} alt="TalentShake" />
							</div>

							<nav className="flex-grow px-6 pb-16 space-y-1">
								{navigationSections.length > 0 && (
									<>
										<h2 className="text-lg font-semibold text-gray-900 mb-2">Navigation</h2>
										{navigationSections.map((section) => (
											<button key={section.id} className="block w-full text-left px-4 py-2 text-base font-medium text-gray-600 rounded-md hover:bg-gray-100" onClick={() => handleSectionClick(section.id)}>
												{section.label}
											</button>
										))}
									</>
								)}

								<h2 className="text-lg font-semibold text-gray-900 mt-6 mb-2">Products</h2>
								{allItems.map((item) => (
									<button key={item.slug} className="block w-full text-left px-4 py-2 text-base font-medium text-gray-600 rounded-md hover:bg-gray-100" onClick={() => handleNavigation(item.slug === 'job-boards' ? jobBoardsLink : item.slug === 'general' ? '/' : `/${item.slug}`)}>
										{item.display_name}
									</button>
								))}
							</nav>

							{/* Close button at the bottom middle */}
							<div className="absolute bottom-4 left-0 right-0 flex justify-center">
								<motion.button whileTap={{ scale: 0.95 }} onClick={() => setIsMobileMenuOpen(false)} className="bg-gray-900 text-white p-3 rounded-full shadow-lg">
									<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
									</svg>
								</motion.button>
							</div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default Navigation;
