import React from 'react';
import { useRegisterSection } from '../hooks/useRegisterSection';

interface PricingFeature {
	text: string;
	included: boolean;
}

interface PricingCard {
	title: string;
	price: string;
	description: string;
	features: PricingFeature[];
	button_text: string;
	button_link: string;
	pill_text: string | null;
	second_button_text: string | null;
	second_button_link: string | null;
}

interface PricingProps {
	title: string;
	subtitle: string;
	cards: PricingCard[];
}

const Pricing: React.FC<PricingProps> = ({ title, subtitle, cards }) => {
	useRegisterSection('pricing', 'Pricing');
	if (!cards || cards.length === 0) {
		return null; // Don't render the section if there are no pricing cards
	}

	return (
		<section className="bg-base-200 py-16 sm:py-24" id="pricing">
			<div className="px-8 max-w-7xl mx-auto">
				<div className="flex flex-col text-center w-full mb-20">
					<h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title || 'Pricing'}</h2>
					{subtitle && <p className="mt-6 text-lg leading-8 text-gray-600">{subtitle}</p>}
				</div>
				<div className="grid md:grid-cols-3 gap-8">
					{cards.map((card, index) => (
						<div key={index} className="relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col">
							{card.pill_text && <span className="absolute top-0 py-1.5 px-4 bg-[#df21aa] text-white rounded-full text-xs font-semibold uppercase tracking-wide transform -translate-y-1/2">{card.pill_text}</span>}
							<div className="flex-1">
								<h3 className="text-xl font-semibold">{card.title || 'Plan'}</h3>
								<p className="mt-4 flex items-baseline">
									<span className="text-5xl font-extrabold tracking-tight">{card.price || 'Contact us'}</span>
								</p>
								{card.description && <p className="mt-6">{card.description}</p>}
								{card.features && card.features.length > 0 && (
									<ul role="list" className="mt-6 space-y-6">
										{card.features.map((feature, featureIndex) => (
											<li key={featureIndex} className="flex items-center">
												<svg className={`flex-shrink-0 w-6 h-6 ${feature.included ? 'text-green-500' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
												</svg>
												<span className={`ml-3 ${feature.included ? 'text-gray-900' : 'text-gray-500'}`}>{feature.text}</span>
											</li>
										))}
									</ul>
								)}
							</div>
							{card.button_text && card.button_link && (
								<a href={card.button_link} className="bg-blue-500 text-white hover:bg-blue-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium">
									{card.button_text}
								</a>
							)}
							{card.second_button_text && card.second_button_link && (
								<a href={card.second_button_link} className="mt-4 block w-full py-3 px-6 border border-gray-300 rounded-full text-center font-medium text-gray-700 hover:bg-gray-50">
									{card.second_button_text}
								</a>
							)}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Pricing;
